@import url('https://fonts.googleapis.com/css2?family=Oxygen&display=swap');

.Header {
  grid-template-areas: "logo nav";
  background-color: #2ad178;
  font-family: 'Oxygen', sans-serif;
  z-index: 2;
  position: inherit;
}

.Nav {
  grid-area: nav;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  justify-items: center;
  font-size: 20px;
  color: white;

}

.header-link {
  font-size: 80;
  color: white;
  text-decoration: none;
}
