.App {
  min-height: 100vh;
  background-color:  rgb(22, 32, 36);
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-contents {
  font-size: calc(10px + 2vmin);
  color: white;
  align-items: center;
  justify-content: center;
}

.profile-icon img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 50%;
  margin: 20px 20px 20px 20px;
}
