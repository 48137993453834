.App .Container {
    position: relative;
}

.App {
    text-align: center;
    background-image: url(../assets/Background_Test1.png);
    background-position: top;
    background-size: cover;
}

.App .itme {
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
}

.App .name {
  font-size: 36px;
  padding: 0;
  margin: 0;
}

.App .title {
  font-size: 20px;
  color: white;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0px;
  text-align: center;
}

.App body {
  background-color: rgba(44, 40, 52, 0.8);
}

.Main p {
  font-size: calc(10px + 2vmin);
  color: white;
  align-items: center;
  justify-content: center;
}

h1 {
  color: white;
}

.subtitle {
  color: white;
  font-size: 16px;
  margin-left: 20%;
  margin-right: 20%;
}

.App .overlay {
  display: block;
  position: inherit;
  z-index: 2;
  margin: 5%;
}

.App .bg-test {
  position: absolute;
  bottom: 2.5%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.hobby-Projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  border-top: 2px dashed #ccc;
  border-bottom: 2px dashed #ccc;
}

.work-Projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Games-grid {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  margin-bottom: 10px;
}

.Companies-grid {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  margin-bottom: 10px;
}

.App .Screencap {
  margin: 10px;
}


@media screen and (max-width: 640px) {
  .App .overlay {
      margin: 0;
    }

    .App .title {
      font-size: 16px;
      color: white;
      margin: 0;
      padding-top: 0;
      padding-bottom: 0px;
      text-align: center;
    }

    .subtitle {
      color: white;
      font-size: 12px;
      margin-left: 10%;
      margin-right: 10%;
    }
}
