@import url('https://fonts.googleapis.com/css2?family=Oxygen&display=swap');

.GameDisplay {
  font-family: 'Oxygen', sans-serif;
  align-items: center;
  justify-content: center;
  z-index: 2;

}

.GameContents {
  display: block;
  justify-content: center;
  padding: 10px;
  width: auto;
  height: auto;
}


.GameImg {
  width: 325px;
  height: 325px;
  object-fit: cover;
  border: 2px solid rgba(255, 255, 255, 1);
}

.GameTitle {
  font-size: 16px;
  font-style: normal;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 640px)  {
  .GameContents {
    display: block;
    justify-content: center;
    margin-top: 2px;
    margin-bottom: 2px;
    padding: 2px;
    width: auto;
    height: auto;
  }

  .GameImg {
    width: 125px;
    height: 125px;
    object-fit: cover;
    border: 1px solid rgba(255, 255, 255, 1);
  }

  .GameTitle {
    font-size: 8px;
    font-style: normal;
    align-items: center;
    justify-content: center;
  }
}
