.bg-objects {
  z-index: 1;
  width: 100%;
}

.parallax-left {
  position: absolute;
  left: 0;
  bottom: 0;
}

.parallax-right {
  position: absolute;
  right: 0;
  bottom: 0;
}

.bg-objects .bg-trees-left {
  margin: 0;
  padding: 0;
  bottom: 0;
}

.bg-objects .bg-trees-right {
  margin: 0;
  padding: 0;
  bottom: 0;
}

@media screen and (max-width: 640px) {
  .bg-objects .bg-trees-left {
    margin: 0;
    padding: 0;
    bottom: 0;
    width: 50%;
    display: block;
  }
  
  .bg-objects .bg-trees-right {
    margin: 0;
    padding: 0;
    bottom: 0;
    width: 50%;
    display: block;
    margin-left: auto; 
    margin-right: 0;
  }
}
